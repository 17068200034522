import {
	isBlank
}
from 'voUtils/tools.js';

import {
	favCount, onFavUpdate
}
from 'voUtils/FavStore.js';

export default {

	data: {
		name: undefined,
	},
	mounted: function () {
		onFavUpdate(() => {
			this.$forceUpdate();
		});
		window.document.onscroll = () => {
			let navBar = document.getElementById('nav');
			if (window.scrollY > navBar.offsetTop) {
				this.active = true;
			} else {
				this.active = false;
			}
		};
	},

	methods: {

		navbarToggle: function () {

			var nav = this.$el;

			if (nav.className === "vo-nav-bar") {
				nav.className += " opened";
			} else {
				nav.className = "vo-nav-bar";
			}


		},
		favCount: favCount,
		goSearch: function () {
			if (isBlank(this.name)) {
				window.location.href = 's';
			} else {
				window.location.href = 's#?name=' + encodeURI(this.name);

			}
		}



	}
};

! function (d, s, id) {
	var js, fjs = d.getElementsByTagName(s)[0];
	if (!d.getElementById(id)) {
		js = d.createElement(s);
		js.id = id;
		js.src = 'https://weatherwidget.io/js/widget.min.js';
		fjs.parentNode.insertBefore(js, fjs);
	}
}(document, 'script', 'weatherwidget-io-js');